.Toastify__toast {
  font-family: "Titillium";
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 16px;
  min-height: 0;
  box-shadow: none;
}

.Toastify__toast-body {
  display: flex;
  margin: 0;
  margin-right: 24px;
}
.Toastify__toast-body > div {
  display: flex;
}

.Toastify__toast-body > svg {
  font-size: 16px;
  max-width: none;
  margin-right: 12px;
}

.Toastify__toast-container {
  width: auto;
}

.Toastify__toast--success {
  background: #25c47c;
}

.Toastify__toast--warning {
  background: "#ffa800";
}
.Toastify__toast--error {
  background: "#e92e21";
}
@media screen and (max-width: 600px) {
  .Toastify__toast-body {
    align-items: flex-start;
  }
  .Toastify__toast-body > svg {
    margin-top: 4px;
  }
}
@media screen and (max-width: 480px) {
  .Toastify__toast-container--top-center {
    top: 1rem;
    left: 1rem;
    right: 1rem;
  }
}
